import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router';

import api from 'api';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ManageListingDashboard from './ManageListingDashboard';
import InvoicesDashboard from './InvoicesDashboard';
import theme from 'config/theme';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import useSearch from 'hooks/useSearch';
import LandlordDashboard from '../landlord-dashboard/LandlordDashboard';
import InvoiceIcon from 'assets/images/landlord-dashboard/InvoiceIcon';
import ManageListingIcon from 'assets/images/landlord-dashboard/ManageListingIcon';
import LandlordDashboardIcon from 'assets/images/landlord-dashboard/LandlordDashboardIcon';

const { colors, fontWeights } = theme;

const QueryParamKey = {
	Tab: 'tab',
};

const Tabs = {
	ManageListing: 0,
	Invoices: 1,
};

const ManageListingTabs = props => {
	const { getParam, setParam } = useSearch();
	const [value, setValue] = useState(+(getParam(QueryParamKey.Tab) || 0));
	const [listings, setListings] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [activeListings, setActiveListings] = useState([]);
	const [inactiveListings, setInactiveListings] = useState([]);
	const [underModerationListings, setUnderModerationListings] = useState([]);
	const { search } = useLocation();

	useEffect(() => {
		const tab = +getParam(QueryParamKey.Tab);
		setValue(tab || 0);
	}, [search]);

	useEffect(() => {
		if (typeof value !== 'number') {
			return;
		}

		setParam(QueryParamKey.Tab, value);
		if (value === Tabs.ManageListing) {
			fetchListings();
		}
	}, [value]);

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <TabContainer>{children}</TabContainer>}
			</div>
		);
	}

	const Tab = ({ children, index, className }) => {
		const color = value === index ? colors.darkBlue : colors.grey20;
		const fontWeight = value === index ? fontWeights.bold : fontWeights.normal;

		return (
			<TabChild
				style={{ borderBottomColor: `${color}`, color: `${color}`, fontWeight: `${fontWeight}` }}
				className={className}
				onClick={() => {
					handleChange(index);
				}}
			>
				{children}
			</TabChild>
		);
	};

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};

	Tab.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
	};

	const fetchListings = isReload => {
		if (!isReload) {
			setIsLoading(true);
		}
		api.property
			.getPropertiesV2()
			.then(res =>
				res.data.map(listing => ({
					...listing,
					videos: listing.videos || [],
				})),
			)
			.then(listings => {
				setIsLoading(false);
				setListings(listings);
				updateListingCollections(listings);
			});
	};

	const updateListingCollections = data => {
		const activeData = data.filter(item => {
			return item.allow_searchable && item.is_searchable;
		});

		const inActiveData = data.filter(item => {
			return (
				(item.allow_searchable && !item.is_searchable) ||
				(!item.allow_searchable &&
					!item.status.errors.some(e => e.error === 'User under moderation'))
			);
		});

		const underModerationData = data.filter(item => {
			return (
				!item.allow_searchable && item.status.errors.some(e => e.error === 'User under moderation')
			);
		});

		setActiveListings(activeData);
		setInactiveListings(inActiveData);
		setUnderModerationListings(underModerationData);
	};

	const handleChange = newValue => {
		setValue(newValue);
	};

	return (
		<Container>
			<TabNavigation>
				{featureFlagContainer.isEnabled(FeatureFlag.LandlordDashboard) ? (
					<>
						<Tab index={0}>
							<ManageListingIcon active={value === 0} style={{ minWidth: '24px' }} />
							Manage listings
						</Tab>
						<Tab className="invoices-tab" index={1}>
							<InvoiceIcon active={value === 1} style={{ minWidth: '24px' }} />
							Invoices
						</Tab>
						<Tab className="dashboard-tab" index={2}>
							<LandlordDashboardIcon active={value === 2} style={{ minWidth: '24px' }} />
							Dashboard
						</Tab>
					</>
				) : (
					<>
						<Tab index={0}>Manage listings</Tab>
						<Tab className="invoices-tab" index={1}>
							Invoices
						</Tab>
					</>
				)}
			</TabNavigation>
			<TabDivider />
			<TabPanel value={value} index={0}>
				<ManageListingDashboard
					isLoading={isLoading}
					history={props.history}
					onChange={() => fetchListings(true)}
					listingData={listings}
					activeData={activeListings}
					inactiveData={inactiveListings}
					underModerationData={underModerationListings}
				/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<InvoicesDashboard />
			</TabPanel>
			{featureFlagContainer.isEnabled(FeatureFlag.LandlordDashboard) && (
				<TabPanel value={value} index={2}>
					<LandlordDashboard />
				</TabPanel>
			)}
		</Container>
	);
};

export default ManageListingTabs;

const Container = styled.div`
	width: 100%;
`;
const TabContainer = styled.div`
	margin-top: 8px;
`;
const TabDivider = styled.div`
	border-bottom: 1px solid;
	border-bottom-color: ${colors.grey05};

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		border-bottom: none;
	}
`;
const TabNavigation = styled.div`
	display: flex;
	padding-left: 0px;
	overflow-y: auto;
	flex-wrap: nowrap;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		justify-content: flex-start;
		::-webkit-scrollbar {
			display: none;
		}
	}
`;
const TabChild = styled.div`
	font-weight: ${fontWeights.bold};
	font-size: 20px;
	min-width: 200px;
	padding-bottom: 10px;
	border-bottom: 2px solid;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
	padding-right: 16px;
	padding-left: 16px;
	gap: 8px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		border-bottom: 2px solid;
		width: 50%;
		gap: 5px;
		padding-right: 8px;
		padding-left: 8px;
	}
`;
